import React from "react";
import classes from "./Notification.module.scss";
import "./Notification.scss";

import bannerMP from "./../../assets/image/banner_escritorio.png";
import { Link } from "react-router-dom";

const ItemNotification = ({ permite, service, type, contrato }) => {
  return (
    <>
      {type === "quota" && permite && (
        <div className="row">
          <div className="col-md-4">
            <div className={classes.boxNotifications}>
              <p className={classes.text}>Tiene cuotas por atender</p>
              <Link
                to={"/mi-sacramental/cartola-de-pago/"}
                className={classes.pago}
              >
                Ir a pagar
              </Link>
            </div>
          </div>
        </div>
      )}
      {type === "quota" && !permite && (
        <div className={classes.boxNotifications}>
          <div className={classes.content_blue}>
            <div className="row">
              <div className="col-7 text-left">
                <p>Usted está al día</p>
              </div>
              <div className="col-5">
                <Link
                  to={"/mi-sacramental/cartola-de-pago/"}
                  className={classes.pago}
                >
                  Ver más
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {type === "service" &&
        (service === "pie" ? (
          // <div className={classes.boxNotifications}>
          //   <p className={classes.text}>
          //     Aquí debes ingresar para aceptar el contrato y pagar el pie
          //   </p>
          //   <Link
          //     to={"/mi-sacramental/acepta-y-pagar-pie"}
          //     className={classes.pago}
          //   >
          //     Aceptar y pagar pie
          //   </Link>
          // </div>
          <></>
        ) : (
          // <div className="box-notification">
          //   <img
          //     src={bannerMP}
          //     className="img-fluid"
          //     style={{ width: 100 + "%" }}
          //     alt=""
          //   />
          //   <div className="row box-notification__text">
          //     <div className="col-12 col-md-7">
          //       <p className="box-description text-left">
          //         Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
          //         do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          //       </p>
          //     </div>
          //     <div className="col-12 col-md-5">
          //       <div className="box-blue">
          //         <div className="row">
          //           <div className="col-7 text-left">
          //             <p>Conocer más sobre el beneficio</p>
          //           </div>
          //           <div className="col-5 text-center">
          //             <Link
          //               to={"/mi-sacramental/cartola-de-pago/"}
          //               className="bt-ms _bt-blue"
          //             >
          //               Acceder
          //             </Link>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // </div>
          <></>
        ))}
    </>
  );
};

export default ItemNotification;
