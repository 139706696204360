import React from 'react';
import { Link } from 'react-router-dom';
import Sesion from '../../../../service/Session';

const ItemAgreement = ({
  producto,
  contrato,
  saldo_total,
  fecha_contrato,
  hidden_button,
  type,
  parque,
  aviso,
  pie,
}) => {
  const UrlBtn = ({ url_btn, name }) => {
    return (
      <Link
        to={{
          pathname: url_btn,
          state: { id: contrato },
        }}
        className="bt-ms _bt-simple-blue"
        onClick={(e) => {
          localStorage.setItem('update', 1);
        }}
      >
        {name}
      </Link>
    );
  };

  const viewCartolaOrAcceptandPay = (contrato, type) => {
    let result = {
      aceptado: true,
      pagado: true,
      url: '',
    };

    Sesion.getInformacionContratos().infcontratos.map((item) => {
      if (item.CONTRATO === contrato) {
        if (type === 'object') {
          result.pagado = false;
        }

        if (item.ACEPTA_CONTRATO == 1) {
          result.aceptado = false;
        }

        if (item.ACEPTA_CONTRATO == 3) {
          result.aceptado = true;
        }
      }
    });

    if (result.aceptado && result.pagado) {
      result.url = 'cartola-de-pago';
      return result;
    }
    result.url = 'acepta-y-pagar-pie';
    return result;
  };

  return (
    <div className="item-agreement">
      <div className="row">
        <div className="col-3">
          <img
            src={'https://sendero.cl/static/media/img_familia.077b05aa.jpg'}
            className="item-agreement__img-product rounded-circle img-fluid"
            alt=""
          />
        </div>
        <div className="col-9 text-left">
          <div className="item-agreement__content">
            <p className="title">{producto}</p>
            <p className="title">{parque}</p>
            <p>
              Contrato N°: <span className="blue">{contrato}</span>
            </p>
            {/*<p>
              Cuota: <span className={classes.blue}>{cuotas}</span>
            </p>*/}
            {/* <p>Saldo: <span className="blue">${new Intl.NumberFormat("de-DE").format(pie?.vigente?.adicional?.saldo_precio  ?? 0)}</span></p> */}
            <p>
              Fecha Contrato:{' '}
              <span className="blue">
                {pie?.vigente?.adicional?.fecha_contrato ?? fecha_contrato}
              </span>
            </p>
            {/* <p className="aviso">
              {aviso ? "Tiene cuotas pendientes" : "Usted está al día"}
            </p> */}
          </div>
        </div>
      </div>
      {!hidden_button && (
        <div className="row">
          <div className="col-6">
            <Link to={`detalle/${contrato}`} className="bt-ms _bt-simple-blue">
              Ver Más
            </Link>
          </div>
          <div className="col-6">
            {
              <UrlBtn
                url_btn={viewCartolaOrAcceptandPay(contrato, type).url}
                name={'Ir a aceptar y pagar'}
              />
            }
            {/* {type === 'object' ? (
              <UrlBtn
                url_btn={'acepta-y-pagar-pie/'}
                name={'Ir a aceptar y pagar'}
              />
            ) : (
              <UrlBtn
                url_btn={'cartola-de-pago/'}
                name={'Ir a aceptar y pagar'}
              />
            )} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemAgreement;
