import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/scss/main.scss';

import * as serviceWorker from './serviceWorker';
ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();