import React, { useState } from 'react';
import Layout from '../Cliente/Layout/Layout';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import TableCuota from '../../components/TabServicios/TableCuota';
import BoxAgreement from '../../components/Agreement/Box/Box';
import { useFormState } from '../../hooks/useForm';
import { getContrato, getUser } from '../../services/Storage.Utils';
import ButtonPay from '../../components/Pay/ButtonPay';
import TitleSection from '../../components/TitleSection/TitleSection';
import bannerMP from './../../assets/image/banner_chico.png';
import Button from '../../components/Button/Button';
import Detail from './Detail';
import './CartolaPayments.scss';

var checkboxes = {};
var cuotas = {};
var selectedCuota = {};
const CartolaPayments = () => {
  const Agreement = getContrato();
  const { state } = useLocation();
  const id =
    typeof state?.id !== 'undefined' ? state.id : Object.keys(Agreement)[0];
  const [activeTab, setActiveTab] = useState(id);
  const [amount, setMontoTotal] = useState(0);
  var active = {};
  active[id] = 'mpu';
  const [mpu, statusMpu] = useState(active);

  const [disabledPagar, setDisabledPagar] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const calculaMontoTotal = () => {
    var valor = 0;
    Object.keys(selectedCuota).map(
      (item) => (valor += parseInt(selectedCuota[item].MONTO_CUOTA))
    );
    setDisabledPagar(valor > 0 ? false : true);
    setMontoTotal(valor);
  };
  const setCheckboxes = (
    NUMERO_CONTRATO,
    NUMERO_CUOTA,
    CONCEPTO_RECAUDACION,
    estado
  ) => {
    var objeto = {};
    objeto = { estado };
    cuotas[NUMERO_CONTRATO + '-' + CONCEPTO_RECAUDACION + '-' + NUMERO_CUOTA] =
      objeto;
    checkboxes = cuotas;
  };
  const onClick = (e, items) => {
    console.log(items);
    console.log(e.currenTarget);
    var objeto = {};
    if (e) {
      [items].forEach((todo) => {
        objeto = {
          NUMERO_CONTRATO: todo.NUMERO_CONTRATO,
          NUMERO_CUOTA: todo.NUMERO_CUOTA,
          CONCEPTO_RECAUDACION: todo.CONCEPTO_RECAUDACION,
          MONTO_CUOTA: parseInt(todo.MONTO_CUOTA),
          NUMERO_DOCUMENTO: todo.NUMERO_DOCUMENTO,
          CUOTA_MOROSA: todo.CUOTA_MOROSA,
          CODIGO_COMERCIO: todo.CODIGO_COMERCIO,
        };
        selectedCuota[
          todo.NUMERO_CONTRATO +
            '-' +
            todo.NUMERO_CUOTA +
            '-' +
            todo.CONCEPTO_RECAUDACION
        ] = objeto;
      });
    } else {
      [items].forEach((todo) => {
        delete selectedCuota[
          todo.NUMERO_CONTRATO +
            '-' +
            todo.NUMERO_CUOTA +
            '-' +
            todo.CONCEPTO_RECAUDACION
        ];
      });
    }
  };
  const cambiarmpu = (contrato, type = '') => {
    var _mantencion = {};
    var _mpu = {};
    if (type !== '') {
      if (type === 'mantencion') {
        _mpu[contrato] = 'mpu';
        statusMpu(_mpu);
      } else {
        _mantencion[contrato] = 'mantencion';
        statusMpu(_mantencion);
      }
    } else {
      _mantencion[contrato] = 'mantencion';
      _mpu[contrato] = 'mpu';
      statusMpu(mpu[contrato] === 'mpu' ? _mantencion : _mpu);
    }
  };
  const [formValues] = useFormState({ selectedCuotaPay: selectedCuota });
  const { selectedCuotaPay } = formValues;
  var cuotasSeleccionadas = JSON.stringify(selectedCuotaPay);

  const renderMensajeIVA = () => {
    const date = new Date();

    if (date.getFullYear() >= process.env.REACT_APP_IVAS) {
      return (
        <>
          <div className="tab-pane box">
            <div className="col-8 text-justify">
              (*) Monto del{' '}
              <strong style={{ letterSpacing: '1.5px' }}>
                IVA A BENEFICIO FISCAL
              </strong>{' '}
              calculado sobre los precios de los servicios afectos a IVA. Para
              más información sobre estos servicios visite{' '}
              <a href="https://sacramental.cl" target="_blank">
                <span style={{ color: '#870064' }}>www.sacramental.cl</span>{' '}
              </a>{' '}
              o comuníquese con nosotros al{' '}
              <span style={{ color: '#000', fontWeight: 'bold' }}>
                800 200 151
              </span>{' '}
              o en cualquiera de nuestras oficinas y cementerios parque.
            </div>
          </div>
        </>
      );
    }

    return <></>;
  };
  return (
    <>
      <Layout bg={'bg-fluid-bottom cover'}>
        <div className="container-cartola-pago">
          <div className="box">
            <TitleSection
              title={'Cartola de pago'}
              subtitle={'Selecciona tu contrato'}
              all_medida_class={''}
              st_medida_class={'d-block d-md-none'}
            />
            <div className="box-intro-pay d-none d-md-block">
              <h2 className="mb-5 tx _green">
                <b>{getUser().nombre}</b>
              </h2>
              <div className="mb-4">
                <p>
                  Pagar su cuenta en Sacramental desde ahora será mucho más
                  fácil.
                </p>
                <p>
                  Aquí conocerá sus servicios contratados y cancelar en pocos
                  pasos su cuota de crédito y mantención.
                </p>
              </div>
              <a
                href="https://sacramental.cl"
                target="_blanck"
                rel="noopener noreferrer"
              >
                <img src={bannerMP} alt="" width="100%" />
              </a>
              <h2 className="d-none d-md-block mt-4 mb-2 title-service">
                Servicios Contratados
              </h2>
            </div>
            <Nav tabs>
              {Object.keys(Agreement).map((i) =>
                [Agreement[i]].map(
                  (item, index) =>
                    typeof Agreement[i].pie === 'undefined' && (
                      <NavItem key={index} className="col-4 col-md-2">
                        <NavLink
                          className={classnames({
                            active: activeTab === item.contrato,
                          })}
                          onClick={() => {
                            toggle(item.contrato);
                          }}
                        >
                          <img
                            src={
                              'https://sendero.cl/static/media/img_familia.077b05aa.jpg'
                            }
                            className={`rounded-circle img-fluid`}
                            alt=""
                          />
                          <p>{item.producto}</p>
                          <p>{item.contrato}</p>
                          <div className="arrow">
                            <i className="fas fa-chevron-right"></i>
                          </div>
                        </NavLink>
                      </NavItem>
                    )
                )
              )}
            </Nav>
          </div>
          {Object.keys(Agreement).map((i) =>
            [Agreement[i]].map((item, index) => (
              <TabContent activeTab={activeTab} key={index}>
                <TabPane tabId={item.contrato}>
                  <div className="box">
                    <BoxAgreement
                      contrato={i}
                      all_medida_class={'d-block d-md-none'}
                    />
                    <div className="box-notification d-block d-md-none">
                      <a
                        href="https://sacramental.cl"
                        target="_blanck"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={bannerMP}
                          className="img-fluid"
                          width="100%"
                          alt=""
                        />
                      </a>
                      {/* <div className="box-notification__text">
                                                <p className='box-description'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            </div> */}
                      <Button
                        classes={'bt-ms _bt-blue'}
                        handleLogin={(e) => {
                          cambiarmpu(i);
                        }}
                        name={'¿Desea pagar mantención perpetua?'}
                      />
                    </div>
                    <Detail {...item} />
                    {Object.keys(item).map(
                      (e, i) =>
                        e !== 'pie' &&
                        typeof item[e] === 'object' &&
                        item[e].permite && (
                          <>
                            {e === 'mantencion' &&
                              typeof item['mpu'] === 'object' && (
                                <div className="box-intro-mantencion mt-4 d-none d-md-block">
                                  <h2 className="text-center">
                                    Seleccione una opción
                                  </h2>
                                  <div className="row">
                                    <div className="col-6 text-center">
                                      <p>
                                        Corresponde al valor que cada año usted
                                        cancela, el cuál nos permite mantener
                                        cada mes los espacios comunes de nuestro
                                        Cementerio Parque. Esto considera el
                                        mejoramiento de caminos, paisajismo,
                                        riego y corte de césped.
                                      </p>
                                      <button
                                        onClick={(e) => {
                                          cambiarmpu(
                                            item.contrato,
                                            'mantencion'
                                          );
                                        }}
                                        className={`${
                                          mpu[item.contrato] === 'mantencion'
                                            ? ''
                                            : 'active'
                                        } moradox`}
                                      >
                                        Mantención Anual
                                      </button>
                                    </div>
                                    <div className="col-6 text-center">
                                      <p>
                                        Corresponde a un beneficio al que usted
                                        puede optar para liberarse del pago
                                        anual de la mantención de nuestro
                                        Cementerio Parque de manera definitiva.
                                      </p>
                                      <button
                                        onClick={(e) => {
                                          cambiarmpu(item.contrato, 'mpu');
                                        }}
                                        className={`${
                                          mpu[item.contrato] === 'mpu'
                                            ? ''
                                            : 'active'
                                        } blue`}
                                      >
                                        Mantención Perpetua
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            <div
                              style={{
                                display:
                                  mpu[item.contrato] === e ? 'none' : 'block',
                              }}
                            >
                              <TableCuota
                                data={item[e]}
                                id={i % 2 === 0 ? 'table-green' : 'table-blue'}
                                onClick={onClick}
                                calculaMontoTotal={calculaMontoTotal}
                                setCheckboxes={setCheckboxes}
                                checkboxes={cuotas}
                                key={i}
                              />
                            </div>
                          </>
                        )
                    )}
                  </div>
                </TabPane>
              </TabContent>
            ))
          )}
          {renderMensajeIVA()}
          <ButtonPay
            amount={amount}
            disabledPagar={disabledPagar}
            cuotasSeleccionadas={cuotasSeleccionadas}
            nameBtPay={'Pagar'}
            nameBtCartola={'Descargar cartola'}
          />
        </div>
      </Layout>
    </>
  );
};

export default CartolaPayments;
