import React, { useState } from 'react';
import BoxAgreement from '../../components/Agreement/Box/Box';
import BoxNotification from '../../components/Notification/BoxNotifications';
import NameUser from '../../components/NameUser/NameUser';
import bannerMP from './../../assets/image/bg.png';
import Layout from '../Cliente/Layout/Layout';
import { Modal, ModalBody, Button } from 'reactstrap';

const Home = () => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <Modal isOpen={open} size={'xl'}>
        <ModalBody>
          <Button
            color=""
            style={{
              position: 'absolute',
              right: '10px',
              border: '1px solid gray',
            }}
            onClick={(e) => setOpen(false)}
          >
            Cerrar
          </Button>
          <div style={{ fontSize: '28px', fontWeight: 'bold', color: '#000' }}>
            NUEVA LEY N° 21.420
          </div>
          <p>Estimado Cliente,</p>
          <br />
          <div className="row">
            <div className="col-lg-7 col-md-6">
              <p>
                A partir del 01 de enero de 2023 comenzará a regir la{' '}
                <span style={{ fontWeight: 'bold', color: '#000' }}>
                  nueva Ley N° 21.420, la cual indica que todos los pagos se
                  servicios se encontrarán afectos a IVA
                </span>
              </p>
              <br />
              <p>
                Debido a esta ley, Jardín Sacramental está obligado legalmente a
                incorporar este impuesto a las Mantenciones y otros servicios,
                los cuales tendrán un{' '}
                <span style={{ fontWeight: 'bold', color: '#000' }}>
                  recargo del 19% por concepto de IVA
                </span>
                , según lo exigido por la autoridad, lo que será recaudado y
                entregado al Servicio de Impuestos Internos a beneficio fiscal.
              </p>
              <br />
              <p>
                Si necesita mas información, puede comunicarse al número
                telfónico{' '}
                <span style={{ fontWeight: 'bold', color: '#800080' }}>
                  800 200 151.
                </span>
              </p>
              <br />
              <p>
                Conozca la nueva Ley N° 21.420{' '}
                <a
                  href="https://www.sacramental.cl/a-partir-del-01-de-enero-nuestros-servicios-estaran-afectos-a-iva/"
                  target={'_blank'}
                >
                  <span style={{ fontWeight: 'bold', color: '#800080' }}>
                    aquí
                  </span>
                </a>
              </p>
            </div>
            <div className="col-lg-5 col-md-6">
              <img
                className="img img-responsive"
                src="https://sendero-api.gux.cl//storage/imagen_ivas.jpg"
                alt="Mensaje IVA"
                height="300"
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Layout bg={'bg-fluid-bottom'}>
        <NameUser st_medida_class="d-block d-md-none" />
        <p
          style={{
            textAlign: 'justify',
            marginTop: '45px',
            color: '#fff',
            padding: '10px',
            backgroundColor: '#811e6c',
          }}
        >
          Estimados Clientes, a partir del 01 de enero de 2023 comenzará a regir
          la nueva Ley Nº 21.420, la cual indica que todos los pagos de
          servicios se encontrarán afectos a IVA. Debido a esta ley, Jardín
          Sacramental está obligado legalmente a incorporar este impuesto a las
          Mantenciones y otros servicios, los cuales tendrán un recargo del 19%
          por concepto de IVA, según lo exigido por la autoridad, lo que será
          recaudado y entregado al Servicio de Impuestos Internos a beneficio
          fiscal.
        </p>
        <br />
        <div className="row ml-md-5 mr-md-5">
          <div className="col-12 col-md-12">
            <BoxAgreement />
          </div>
          <div className="col-xs-12 order-first order-md-last col-md-12">
            <BoxNotification />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-12">
            <a
              href="https://sacramental.cl"
              target="_blanck"
              rel="noopener noreferrer"
            >
              <img src={bannerMP} className="img-fluid" width="100%" alt="" />
            </a>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Home;
