import React, { useState } from "react";
import Button from "../../components/Button/Button";
import Title from "../../components/Title/BoxText";
import Layout from "../Login/Layout/Layout";
import { Link } from "react-router-dom";
import Alert from "../../components/Alert/Alert";
import * as Api from "../../api";

const Recover = () => {
  const [msg, setMsg] = useState("");
  const [status, setStatus] = useState("");
  const [loader, setLoader] = useState(false);
  const [inputs, setInputs] = useState({
    rut: "",
  });

  const handleRecover = async () => {
    const { rut } = inputs;
    if (rut === "") {
      setMsg("Debe ingresar un rut");
      setStatus('danger');
      return false;
    }
    setLoader(true);
    const response = await Api.Recover(inputs);
    const { result } = response;
    setStatus(response.status);
    if (response.status === "success") {
      setMsg(result.msg);
    } else {
      setMsg(result.msg);
    }
    setLoader(false);
  };
  const handleInput = (e) => {

    let valor = e.target.value;
    
    if (e.target.name === "rut") {
      valor = valor
        .replace(/[.-]/g, "")
        .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
     
    }

    setInputs({
      ...inputs,
      [e.target.name]: valor,
    });

  };

  return (
    <Layout classes={"ct-public"} bg={"bg-fluid-bottom"}>
      <div className="container-fluid">
        <div className="row">
          <div className="offset-md-3 col-md-6 bg-mobile">
            <div className="ct-public__form">
              <Title
                title1={"Recuperar"}
                title2={"Contraseña"}
                subtitle={
                  "Ingresa tu RUT y enviaremos una clave provisoria al correo registrado."
                }
              />
              <form>
                <div className="content-form">
                  <div className="form-group">
                    <label>RUT</label>
                    <input
                      type="text"
                      id="rut"
                      name="rut"
                      className="form-control"
                      onChange={handleInput}
                      placeholder="12.345.678-8"
                      value={inputs?.rut}
                    />
                  </div>
                  <Alert msg={msg} status={status} />
                </div>
                <div className="form-group text-center">
                  <Button
                    classes={"bt-ms _bt-green"}
                    handleLogin={handleRecover}
                    loader={loader}
                    name={"Recuperar"}
                  />
                </div>
                <div className="form-group row">
                  <div className="col-12 text-center">
                    <Link to="/" className="recover tx _black">
                      Volver
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Recover;
