import { now } from "moment";
import React from "react";
import classes from "./Footer.module.scss";

const Footer = () => {

  const year = () => {
    let d = new Date();
    return  d.getFullYear(); 
  }

  return (
    <div className={classes.contentFooter}>
      <footer className="text-center">
        <p>Término y condiciones</p>
        <p>Política de privacidad © {year()} Mi Sacramental.</p>
        <p>Todos los derechos reservados</p>
      </footer>
    </div>
  );
};

export default Footer;
