import React, { useState } from 'react';
import webpayTransbank from '../../../assets/images/mi-sacramental/webpay_transbank.jpg';
import khipu from '../../../assets/images/mi-sacramental/khipu.jpg';
import Button from '../Button/Button';
import Alert from './../Alert/Alert';
import { POST } from '../../services/Api';
import { getUser } from '../../services/Storage.Utils';
import AmountPay from './AmountPay';
import * as Api from '../../api';
import './Pay.scss';

import { logout } from '../../services/Storage.Utils';

const ButtonPay = ({
  cuotasSeleccionadas,
  amount,
  disabledPagar,
  nameBtPay,
  nameBtCartola,
}) => {
  const [paymentType, setPaymentType] = useState(0);
  const [url, setUrl] = useState(0);
  const [token_ws, setTokenWs] = useState(0);
  const [loader, setLoader] = useState(false);
  const [msg, setMsg] = useState('');
  const [status, setStatus] = useState('');
  const [id_token_ws] = useState(
    'token_ws_' + Math.floor(Math.random() * 999999)
  );

  function handleInputChangeOption(event) {
    const target = event.target;
    const value = target.value;
    setPaymentType(value);
  }
  const onClickPagarWebPay = async () => {
    if (amount == null || amount <= 0) {
      setStatus('danger');
      setMsg('Necesita colocar Monto');
      return false;
    }

    if (paymentType == null || paymentType <= 0) {
      setStatus('danger');
      setMsg('Debe seleccionar un método de pago');
      return false;
    }

    setLoader(true);
    const data = await Api.ActionBuyTransbank({
      rut: getUser().rut,
      email: getUser().email,
      nombre: getUser().nombre,
      cuotasSeleccionadas,
      amount: amount,
    });
    const { result, next_page, token_ws } = data;
    if (typeof data !== 'undefined') {
      setStatus(result);
      setLoader(false);
      if (result === 'success') {
        //document.getElementById(token_ws).value = token_ws;
        let all_records = document.querySelectorAll('#form_webpay');
        all_records.forEach((c) => {
          c.action = next_page;
          c[0].value = token_ws;
        });
        //setTokenWs(token_ws);
        //setUrl(next_page);
        logout();
        document.getElementById('form_webpay').submit();
      } else {
        setStatus('danger');
        setMsg('Ocurrió un problema al conectar con Transbank');
      }
    } else {
      setLoader(false);
      setStatus('danger');
      setMsg('Error al conectar');
    }
  };
  const onClickPagarKiphu = async () => {

    if (amount == null || amount <= 0) {
      setStatus('danger');
      setMsg('Necesita colocar Monto');
      return false;
    }

    if (paymentType == null || paymentType <= 0) {
      setStatus('danger');
      setMsg('Debe seleccionar un método de pago');
      return false;
    }

    setLoader(true);
    const response = await Api.ActionBuyKhipu({
      rut: getUser().rut,
      email: getUser().email,
      //email: 'fericell2909@gmail.com',
      nombre: getUser().nombre,
      cuotasSeleccionadas,
      amount: amount,
      is_pago_rapido: 0
    });
    if (typeof response !== 'undefined') {
      // const { payment_url, status } = data;
      // setStatus(status);
      // setLoader(false);
      // if (status === 'success') {
      //   setUrl(payment_url);
      //   document.getElementById('form_webpay').submit();
      // } else {
      //   setStatus('danger');
      //   setMsg('Ocurrió un problema al conectar con Khipu');
      // }

      console.log(response);
      if(response.status){
          console.log(response.payment_url);
          window.location.href = response.payment_url;
          setStatus('success');
          setMsg('Redirigiendo al proceso de pago...');
      }
     
      setStatus('danger');
      setMsg('Ocurrió un problema al conectar con Khipu');

    } else {
      setLoader(false);
      setStatus('danger');
      setMsg('Error al conectar');
    }
  };
  return (
    <div className="box-method-pay">
      <form name="form_webpay" action={url} method="POST" id="form_webpay">
        <input type="hidden" name="token_ws" value={token_ws} />
      </form>
      <AmountPay amount={amount} />
      <div className="checkout box">
        <div className="sl-method-pay row mt-3 text-center">
          <label className="col-12 text-md-left">
            <h4 className="text-uppercase mb-2">
              Seleccione el método de pago
            </h4>
          </label>
          <div className="col-6">
            <label className="radio2 box">
              <div className="row">
                <div className="col-12 col-md-2">
                  <input
                    type="radio"
                    className="mnt-opcion"
                    name="paymentType"
                    defaultChecked={false}
                    value={1}
                    style={{ zoom: '2' }}
                    onChange={(e) => handleInputChangeOption(e)}
                  />
                </div>
                <div className="col-12 col-md-10 text-left">
                  <p>Tarjeta Crédito / Débito</p>
                  <img alt="" src={webpayTransbank} />
                  <span className="check2"></span>
                </div>
              </div>
            </label>
          </div>
          <div className="col-6">
            <label className="radio2 box">
              <div className="row">
                <div className="col-12 col-md-2">
                  <input type="radio" name="paymentType" value={2} style={{ zoom: '2' }} onChange={(e) => handleInputChangeOption(e)} />
                </div>
                <div className="col-12 col-md-10 text-left">
                  <p>Transferencia desde su banco</p>
                  <img alt="" src={khipu} />
                  <span className="check2"></span>
                </div>
              </div>
            </label>
          </div>
        </div>
        <Alert msg={msg} status={status} />
        <div className="row">
          <div className="col-12 offset-md-4 col-md-4 text-center">
            <div className="d-block d-md-none">
              {/* <Button
                classes={'bt-ms _bt-blue'}
                loader={loader}
                name={nameBtCartola}
              /> */}
            </div>
          </div>
          <div className="col-12 offset-md-4 col-md-4 text-center">
            <Button
              classes={'bt-ms _bt-green'}
              handleLogin={paymentType == 1 ? onClickPagarWebPay : onClickPagarKiphu}
              loader={loader}
              name={nameBtPay}
              disabled={disabledPagar}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ButtonPay;
