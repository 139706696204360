import React, { Component } from 'react'
import { Link } from "react-router-dom"
import { slide as Slide } from "react-burger-menu"
import { Row, Col } from 'reactstrap'
import LogoSenderoWhite from '../../../assets/images/logo/LogoSenderoWhite.png'
import Icon247white from '../../../assets/images/icons/247-white.png'

class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const { component, menuOpen, handleMenuClick } = this.props;
        return (
            <Slide show={menuOpen}>
                <div className="menu-mobile">
                    <div className="menu-content-link text-center">
                        <Link onClick={handleMenuClick} to={"/"} className="text-center">
                            <img src={LogoSenderoWhite} className="img img-logo-white text-center" alt="" />
                        </Link>
                        <br /><br />
                        {component}
                    </div>
                    <div className="text-center info-contact-mobile">
                        <Row>
                            <Col xs="2" className="text-left">
                                <div>
                                    <img src={Icon247white} className="img-icons" alt="" />
                                </div>
                            </Col>
                            <Col xs="9">
                                <div className="text-left">
                                    <a href="tel:6000741380">
                                        <p className="contac-lateral-mobile">CONTÁCTENOS LAS 24 HORAS</p>
                                        <p className="phone-lateral-mobile">600 074 1380</p>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col xs={12}><p className="registro">© 2021 Parque del sendero</p></Col>
                        </Row>
                    </div>
                </div>
            </Slide>
        )
    }
}
export default Sidebar
