import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReactHtmlParser from "react-html-parser";
import Api from "../../service/Api";
import Flotante from '../Flotante';
import MegaMenu from './MegaMenu/megaMenu';
import menuMobile from "./MegaMenu/dataMenuMM";
import menuHorizontal from "./dataMenuSite";
import MenuSendero from "./Menu.jsx";

class DesktopRoutes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            nombrePlan: '',
            pagorapido: {},
            popup: {},
            detalle: {},
            misendero: {},
            registrar: {},
            alerta: {},
            alertadetalle: {},
        };
        this.toggle = this.toggle.bind(this);
    }
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    componentWillMount() {
        //this.getConf();
    }
    async getConf() {
        const dat = {};
        const data = await Api.getConf(dat);
        this.setState({
            pagorapido: data.result[0],
            popup: data.result[1],
            detalle: data.result[1].detalle[0],
            misendero: data.result[2],
            registrar: data.result[4],
            alerta: data.result[3],
            alertadetalle: data.result[3].detalle[0],
            modal: data.result[1].status === 1 ? true : false
        });
    }

    render() {
        const { pagorapido, misendero, registrar, popup, detalle, modal, alerta, alertadetalle } = this.state;
        return (
            <>
                {typeof popup !== 'undefined' && (
                    <Modal isOpen={modal} toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle}></ModalHeader>
                        <ModalBody style={{ textAlign: 'center' }}>
                            {
                                typeof detalle !== 'undefined' && (
                                    <div>
                                        {ReactHtmlParser(detalle.content)}
                                    </div>
                                )
                            }
                        </ModalBody>
                    </Modal>
                )}
                <div className="menu-fixed">
                    {typeof alerta !== 'undefined' && alerta.status === 1 && (
                        ReactHtmlParser(alertadetalle.content)
                    )}
                    <MegaMenu
                        pagorapido={pagorapido}
                        misendero={misendero}
                        registrar={registrar}
                        menuSite={
                            <MenuSendero
                                menuMobile={menuMobile.menu}
                                menuHorizontal={menuHorizontal.menu}
                            />
                        }
                    />
                    {this.props.component}
                </div>
                <Flotante />
            </>
        );
    }
}

export default DesktopRoutes;