import React from 'react';
import Layout from '../Cliente/Layout/Layout';
import { Container } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { getContrato } from '../../services/Storage.Utils';
import './DetailAgreement.scss';

const DetailAgreement = (props) => {
    const { id } = useParams();
    const Agreement = getContrato()[id];
    return (
        <Layout>
            <div className="row">
                <div className="col-12 col-md-4 sin-padding">
                    <div className="box-agreement-detail">
                        <div className="imagen">
                            <a href="https://sacramental.cl" target="_blanck" rel="noopener noreferrer"><img src="https://sendero-api.gux.cl//storage/parques/January2021/xc5uEvb9Xcpi0KIK5Puq.jpg" width="100%" height="150" /></a>
                        </div>
                        <Container>
                            <div className="box-agreement-detail__name">
                                <h2>{Agreement.producto}</h2>
                                <p>Fecha Contrato: {Agreement.pie?.vigente?.adicional?.fecha_contrato}</p>
                            </div>
                        </Container>
                        <Container className="container-description">
                            <div className="container-description__detail">
                                <div className="content">
                                    <h2>Descripción</h2>
                                    <div className="title-line"></div>
                                    <h3>{Agreement.parque}</h3>
                                    <p>Contrato N°: {Agreement.contrato}</p>
                                    {/* <p>Capacidad Utilizada: {Agreement.capacidad}</p> */}
                                    <p>Fecha Contrato: {Agreement.pie?.vigente?.adicional?.fecha_contrato}</p>
                                </div>
                                {/*
                                <div className="content">
                                    <h2>Estado de cuota</h2>
                                    <div className="title-line"></div>
                                    <p>Paga actual 3 de 36</p>
                                    <p className="tx _blue">Ud. tiene 2 cuotas morosas de mantención 2021</p>
    </div>
    */}
                                <div className="content">
                                    <Link to={`/mi-sacramental/contratos`} >Descargar Contrato</Link>
                                    <Link to={{ pathname: "/mi-sacramental/cartola-de-pago/", state: { id: Agreement.contrato } }} >Ver Cartola de Pago</Link>
                                    <Link to={`/mi-sacramental/historial/${Agreement.contrato}`} >Ver Historial de Pago</Link>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </div>
        </Layout>
    )
}


export default DetailAgreement;