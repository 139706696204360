import React, { Component } from "react";
import { Link } from "react-router-dom";

const items = [
  {
    name: "web",
    icon: "far fa-file-alt",
    link: "https://sacramental.cl",
    color: "negro",
    target: "",
  },
  {
    name: "pago",
    icon: "far fa-credit-card",
    link: "/mi-sacramental/resumen",
    color: "morado",
    target: "",
  },
  // {
  //   name: "email",
  //   icon: "far fa-envelope",
  //   link: "/contacto",
  //   color: "blue",
  //   target: "_self",
  // },
  // {
  //   name: "pago",
  //   icon: "far fa-credit-card",
  //   link: "/pago-rapido/auth",
  //   color: "green",
  //   target: "_self",
  // },
  // {
  //   name: "cotiza",
  //   icon: "far fa-file-alt",
  //   link: "/cotizar",
  //   color: "blue",
  //   target: "_self",
  //   class: "d-none d-md-block",
  // },
  // {
  //   name: "compra-linea",
  //   icon: "fas fa-shopping-cart",
  //   link: "/compra-en-linea",
  //   color: "green blue_mobile",
  //   target: "_self",
  // },
  
  // {
  //   name: "teléfono",
  //   icon: "fas fa-phone",
  //   link: "tel:6007363376",
  //   color: "blue",
  //   target: "_blank",
  //   class: "d-none d-md-block",
  // },

];

class Flotante extends Component {
  render() {
    return (
      <>
        <div className="flotante">
          {items &&
            items.map((flot, index) => (
              <div key={index} className={flot.class}>
                {flot.target === "_self" ? (
                  <Link to={flot.link}>
                    <div className={flot.color + " flotante__icon"}>
                      <i className={flot.icon}></i>
                    </div>
                  </Link>
                ) : (
                  <a href={flot.link} target="_blanck" rel="noopener noreferrer">
                    <div className={flot.color + " flotante__icon"}>
                      <i className={flot.icon}></i>
                    </div>
                  </a>
                )}
              </div>
            ))}
        </div>
      </>
    );
  }
}

export default Flotante;
