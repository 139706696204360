import React, { Component } from 'react';
import Layout from '../../mi-sacramental/Page/Login/Layout/Layout';
class NotFound extends Component {

    render() {
        return (
                    <>
                    <Layout classes={'ct-public'} bg={'bg-fluid-bottom'}>
                    <div className="row justify-content-center w-100">
                        <div className="col-md-8 bg-mobile">
                            <div className="ct-public__form text-center">
                                <div className="not-found-container">
                                    <h2><b>Lo sentimos...</b></h2>
                                    <h3>Contenido no encontrado</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Posts /> */}
                </Layout>
            </>
        );
    }
}
export default NotFound;