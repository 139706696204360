import React, { Component } from "react";
import { Link } from "react-router-dom";
import menu from "./dataMenuMM";
import "./MegaMenu.scss";
import Url from "./Url";
import pr from "./../../../assets/images/icons/pagorapido.svg";
import em from "./../../../assets/images/icons/emergencia.svg";
import ms from "./../../../assets/images/icons/misenderoperfil.svg";
class MegaMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMM: false,
    };
    this.ShowMegaMenu = this.ShowMegaMenu.bind(this);
  }
  ShowMegaMenu() {
    this.setState((prevState) => ({
      showMM: !prevState.showMM,
    }));
  }

  render() {
    const { showMM } = this.state;
    const { pagorapido, misendero, registrar, menuSite } = this.props;
    var menu__top = [
      // {
      //   name: "PAGO RAPIDO",
      //   url: "/pago-rapido",
      //   status: pagorapido.status,
      //   color: "blue",
      //   icon: pr,
      // },
      // {
      //   name: "MI SENDERO",
      //   url: "/mi-sendero",
      //   status: misendero.status,
      //   color: "green",
      //   icon: ms,
      // },
      // {
      //   name: "EMERGENCIA",
      //   url: "tel:6007363376",
      //   status: 1,
      //   color: "red",
      //   icon: em,
      // },

    ];

    return (
      <div className="mega-menu" >
        
        {menuSite}
      </div>
    );
  }
}
export default MegaMenu;
