import React, { useState } from "react";
import Title from "../../components/Title/BoxText";
import Layout from "../Login/Layout/Layout";
import { Link } from "react-router-dom";
import Formulario from "../../components/Forms/Form";
import Button from "../../components/Button/Button";
import Alert from "../../components/Alert/Alert";
import * as Api from "../../api";
import cedula1 from "../../../assets/images/SD__CI_ANTIGUA_VF.png";
import cedula2 from "../../../assets/images/SD__CI_NUEVA_VF.png"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const Register = () => {
  var answerVal = {};
  const [statusView, setStatusView] = useState(1);
  const [questions, setQuestions] = useState();
  const [answer, setAnswer] = useState({});
  const [loader, setLoader] = useState(false);
  const [idChallenge, setIdChallenge] = useState(0);
  const [msg, setMsg] = useState("");
  const [status, setStatus] = useState("");
  const [inputs, setInputs] = useState({
    rut: "",
    n_doc: "",
    email: "",
    nombre: "",
    telefono: "",
    celular: "",
    direccion: "",
    password: "",
    password_confirm: "",
  });

  const [isOpen,setIsOpen] = useState(false);
  const [tooltipOpen,settooltipOpen] = useState({isOpen: false, images: null});

  const handleClickFoto = (e, src) => {
    settooltipOpen({isOpen: true , images: src});
  }

  const handleCheck = async () => {
    const { rut, n_doc } = inputs;
    if (rut === "") {
      setMsg("Debe ingresar un rut");
      setStatus("danger");
      return false;
    }
    if (n_doc === "") {
      setMsg("Debe ingresar N° de documento");
      setStatus("danger");
      return false;
    }

    setLoader(true);
    const response = await Api.RegisterGetQuestion(inputs);
    console.info(response);
    const { result } = response;
    setStatus(response.status);
    if (response.status === "success") {
      const { ConsultaResult } = result;
      setQuestions(ConsultaResult.DETALLE.DESAFIO.PREGUNTAS);
      setIdChallenge(ConsultaResult.RESUMEN.IDCHALLENGE);
      localStorage.setItem("rut", response.rut);
      localStorage.setItem("nombre", response.nombre);
      localStorage.setItem("email", response.email);
      setStatusView(2);
    } else {
      const { validations } = response;
      setMsg(validations.result.msg);
      setStatus(validations.status);
    }
    setLoader(false);
  };
  const handleValidateAnswer = async () => {
    const { rut } = inputs;
    if (rut === "") {
      setMsg("Debe ingresar un rut");
      setStatus("danger");
      return false;
    }
    setLoader(true);
    
    const response = await Api.RegisterValidateQuestion({"answer": JSON.stringify(answer),"idChallenge": idChallenge , "rut": rut});

    const { result } = response;
    setStatus(response.status);
    if (response.status === "success") {
      setStatusView(3);
      localStorage.setItem('rut',rut);
    } else {
      setMsg(result.msg);
    }
    setLoader(false);
  };
  const handleInput = (e) => {
    let valor = e.target.value;
    if (e.target.name === "rut") {
      valor = valor
        .replace(/[.-]/g, "")
        .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
     
    }

    setInputs({
      ...inputs,
      [e.target.name]: valor,
    });

  };
  const handleOptionChange = (changeEvent, pregunta) => {
    var objeto = {};
    objeto = {
      CODIGO_PREGUNTA: pregunta.trim(),
      CODIGO_RESPUESTA: changeEvent.target.value.trim(),
    };
    answerVal[pregunta] = objeto;
    var data = { ...answerVal, ...answer };
    setAnswer(data);
  };
  return (
    <Layout classes={"ct-public"} bg={"bg-fluid-bottom"}>
      <div className="offset-md-3 col-md-6 bg-mobile">
        {statusView === 1 && (
          <div className="ct-public__form">
            <Title
              title1={"Registrarse en"}
              title2={"Mi Sacramental"}
              subtitle={"Ingresa tus datos para continuar"}
            />
            <form>
              <div className="content-form">
                <div className="form-group">
                  <label>RUT</label>
                  <input
                    type="text"
                    id="rut"
                    name="rut"
                    className="form-control"
                    onChange={handleInput}
                    placeholder="12.345.678-8"
                    value={inputs?.rut}
                  />
                </div>
                <div className="form-group">
                  <label>N° DE DOCUMENTO</label>
                  <input
                    type="text"
                    id="n_doc"
                    name="n_doc"
                    className="form-control"
                    onChange={handleInput}
                    placeholder="123456789"
                  />
                </div>
                <Alert msg={msg} status={status} />
              </div>
              <div className="form-group text-center">
                <Button
                  classes={"bt-ms _bt-green"}
                  handleLogin={handleCheck}
                  loader={loader}
                  name={"Registrarse"}
                />
              </div>
              <div className="form-group row">
                <div className="col-9 text-left">
                  {/* <Link to={"/"} className={"recover tx _black"}>
                    ¿Cómo ver el N° de Documento?
                  </Link> */}
                  <p className="pin" onClick={() => {setIsOpen(!isOpen)}}>¿Como ver el N de Documento? <i id="TooltipExample" className="fa fa-info-circle" style={{ 'color': '#811e6c', 'fontSize': 14 + 'px' }}></i></p>
                  <div className={"cedula__content " + (isOpen ? 'open' : 'close')}>
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <img src={cedula1} alt="" onClick={(e) => { handleClickFoto(e, cedula1) }} style={{height: '150px'}} />
                        <p className="title-doc">N de serie</p>
                        <p className="subtitle">Cédula <span>Antigua</span></p>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <img src={cedula2} alt="" onClick={(e) => { handleClickFoto(e, cedula2) }} style={{height: '150px'}} />
                        <p className="title-doc">N de documento</p>
                        <p className="subtitle">Cédula <span>Nueva</span></p>
                      </div>
                    </div>
                    {tooltipOpen.isOpen && (
                      <Lightbox
                        mainSrc={tooltipOpen.images}
                        imageCaption={''}
                        onCloseRequest={() => settooltipOpen({isOpen: false, images: null})}
                      />
                    )}
                  </div>
                </div>
                <div className="col text-right">
                  <Link to={"/"} className={"recover tx _black"}>
                    Volver
                  </Link>
                </div>
              </div>
            </form>
          </div>
        )}
        {statusView === 2 && (
          <>
            <div className="text-center">
              Por favor responda las siguientes preguntas{" "}
            </div>
            {questions &&
              questions.map((gh, i) => (
                <div className="container-preguntas" key={i}>
                  <div className="container-preguntas__title">
                    {gh.PREGUNTA}
                  </div>
                  {gh.ALTERNATIVAS.map((pr, e) => (
                    <p key={e}>
                      <input
                        type="radio"
                        value={pr.CODIGO_RESPUESTA}
                        name={gh.CODIGO_PREGUNTA}
                        onChange={(ev) => {
                          handleOptionChange(ev, gh.CODIGO_PREGUNTA);
                        }}
                      />{" "}
                      {pr.RESPUESTA}
                    </p>
                  ))}
                </div>
              ))}
            <Alert msg={msg} status={status} />
            <div className="form-group text-center">
              <Button
                classes={"bt-ms _bt-green"}
                handleLogin={handleValidateAnswer}
                loader={loader}
                name={"Siguiente"}
              />
            </div>
          </>
        )}
        {statusView === 3 && <Formulario />}
      </div>
    </Layout>
  );
};

export default Register;
